import React from "react";
import { useEffect, useContext } from "react";
import StoreContext from "../../contexts/StoreContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const { REACT_APP_KYC_BACKEND } = process.env;

const FlowRegular = () => {
  const { setQueryParam } = useContext(StoreContext);

  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.href.split("?")[1];
    const queryParams = {};
    if (queryString) {
      queryString.split("&").forEach((param) => {
        const [key, value] = param.split("=");
        queryParams[key] = decodeURIComponent(value);
      });
    }
  
    const fetchData = async () => {
      try {
        if (Object.keys(queryParams).indexOf("t") === -1) {
          navigate("/invalid");
          return null;
        }
        const validateToken = await axios.post(`${REACT_APP_KYC_BACKEND}/v1/t/validate`, {
          t: queryParams.t,
          y: queryParams.y,
        });
  
        if (validateToken.status === 200) {
          sessionStorage.setItem("mkyc", JSON.stringify(queryParams));
          if (validateToken.data.locale) sessionStorage.setItem("mkyc_locale", validateToken.data.locale);
  
          navigate("/consent");
        } else {
          navigate("/invalid");
        }
        setQueryParam(queryParams);
      } catch (e) {
        console.error(e);
        navigate("/invalid");
        return null;
      }
    };
    fetchData();
  }, [navigate, setQueryParam]);
  return <div></div>;
};

export default FlowRegular;
