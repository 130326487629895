/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq */
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Container } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SubmitConfirmation from "../ui/SubmitConfirmation";
import SubmitCompletion from "../ui/SubmitCompletion";
import SubmitHasErrors from "../ui/SubmitHasErrors";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

const { REACT_APP_KYC_BACKEND } = process.env;

export default (props) => {
  const { IdDetails, passportDetails, liveTestDetails, auditVideo, createLivenessApiData, s3Temp, refId, onBackClick, jwt,
    activeStep,
    setActiveStep,
    setIdFrontPage,
    setPassportDocument,
    setIdBackPage,
    setProofOfAddress,
    isLocalForeign,
    setIsLocalForeign,
    firstName,
    lastName,
    dob,
    idNumber,
    // address,
    addressLine1,
    addressLine2,
    city,
    province,
    state,
    postal,
    expiryDate,
    issueDate,
    passportNumber,
  
    passportDocument,
    IdFrontPage,
    IdBackPage,
    proofOfAddress,
  
    email,
    mobileNumber,
    mobileCountryCode,
  
    employmentPassFront,
    employmentPassBack,
    finNumber,
    occupation,
    industry,
    epDateOfApplication,
    epDateOfIssue,
    epDateOfExpiry,
    epGender,
    employer,
    epBackQR,
    isEpUpload
  } = props;

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [hasErrors, setHasErrors] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [redoCheck, setRedoCheck] = useState(0);
  const submitURL = REACT_APP_KYC_BACKEND + "/registration/kyc/submit/singapore";
  const { t } = useTranslation();

  const submit = async () => {
    const s3Keys = Object.keys(s3Temp).map((s3Key) => {
      // return { identifier: s3Key, key: s3Temp[s3Key] || "" };
      return { key: s3Temp[s3Key] || "" };
    });
    const requestData = new FormData();
    requestData.append(
      "Id",
      JSON.stringify({
        ...IdDetails,
        session_id: createLivenessApiData,
        reference_id: refId,
        keys: s3Keys,
        jwt,
      })
    );
    requestData.append("Passport", JSON.stringify(passportDetails));
    requestData.append("Liveness", JSON.stringify(liveTestDetails));

    if(isEpUpload) {
      requestData.append("EmploymentPass", JSON.stringify({
        finNumber:finNumber,
        occupation:occupation,
        employer:employer,
        dateOfApplication:epDateOfApplication,
        dateOfIssue:epDateOfIssue,
        dateOfExpiry:epDateOfExpiry,
        gender:epGender,
        epQr:epBackQR
      }));
    }
    
    requestData.append("Keys", JSON.stringify(s3Temp));
    requestData.append("ProofOfAddress", JSON.stringify({
      proofOfAddressKey:s3Temp.proofOfAddress
    }));

    // console.log("==============submitting============");
    // console.log(s3Temp);
    // console.log(passportDetails);
    // console.log(liveTestDetails);
    // console.log({
    //   ...IdDetails,
    //   session_id: createLivenessApiData,
    //   reference_id: refId,
    //   keys: s3Keys,
    // });
    // console.log({proofOfAddressKey:s3Temp.proofOfAddress})
    // console.log("==============submitting============");

    try {
      setRedoCheck(0)
      let response = await axios.post(submitURL, requestData, {});
      console.log(response);

      sessionStorage.removeItem("mkyc")
    } catch (error) {
      console.log(error)
      // console.log("error response === > ",error.response)
      setHasErrors(1);
      if(error.response.data?.message == "Could not recognize a face. Try again"){
        setErrorMsg(
          //Please capture a new photo of your ID/Passport, as the system is unable to recognize your face.
          t("SubmitPage.failReadPic")
        );
        setRedoCheck(1)
      }else{
        setErrorMsg(
          // "There is an issue with the server. We apologize for the inconvenience and please try again later."
          t("SubmitPage.fail")
        );

      }
      
      
      console.error(error);
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    setCurrentStep(currentStep + 1);
    try {
      let submitting = await submit();
      // console.log(submitting);
    } catch (error) {
      // Handle error if needed
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleBackStep = () => {
    if (onBackClick) {
      onBackClick();
    }
  };
  return (
    <Suspense fallback="...loading">
      <Container disableGutters maxWidth="false" sx={{ padding: 0 }}>
        {!loading && currentStep == 0 && <SubmitConfirmation isLocalForeign={isLocalForeign} 
          firstName={firstName}
          lastName={lastName}
          dob={dob}
          idNumber={idNumber}
          // address={address}
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          city={city}
          province={province}
          state={state}
          postal={postal}
          expiryDate={expiryDate}
          issueDate={issueDate}
          passportNumber={passportNumber}

          passportDocument={passportDocument}
          IdFrontPage={IdFrontPage}
          IdBackPage={IdBackPage}
          proofOfAddress={proofOfAddress}
          email={email}
          mobileNumber={mobileNumber}
          mobileCountryCode={mobileCountryCode}

          employmentPassFront={employmentPassFront}
          employmentPassBack={employmentPassBack}
          finNumber={finNumber}
          occupation={occupation}
          industry={industry}
          epDateOfApplication={epDateOfApplication}
          epDateOfIssue={epDateOfIssue}
          epDateOfExpiry={epDateOfExpiry}
          epGender={epGender}
          employer={employer}
          isEpUpload={isEpUpload}
        />}
        {!loading && currentStep == 1 && hasErrors == 1 && <SubmitHasErrors 
          msg={errorMsg} redoCheck={redoCheck} setCurrentStep={setCurrentStep} setActiveStep={setActiveStep}
          setIdFrontPage={setIdFrontPage}
          setPassportDocument={setPassportDocument}
          setIdBackPage={setIdBackPage}
          setProofOfAddress={setProofOfAddress} />}
        {!loading && currentStep == 1 && hasErrors == 0 && <SubmitCompletion />}

        {loading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100px" // Set the desired height (adjust as needed)
            marginTop="170px"
            marginBottom="170px"
          >
            <CircularProgress size={100} color="primary" /> {/* Adjust the size and color */}
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" sx={{ borderTop: "1px solid lightgrey", mt: 3 }}>
          {currentStep === 0 && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ fontFamily: "system-ui", borderRadius: "20px", fontSize: "17px", fontWeight: 500, textTransform: "none", mt: 3, mb: 3 }}
              onClick={handleBackStep}
            >
              {t("general.back")}
            </Button>
          )}
          {currentStep == 0 && (
            <Button
              variant="contained"
              color="primary"
              sx={{ fontFamily: "system-ui", borderRadius: "20px", fontSize: "17px", fontWeight: 500, textTransform: "none", mt: 3, mb: 3 }}
              onClick={handleSubmit}
            >
              {t("SubmitPage.submitB")}
            </Button>
          )}
        </Box>
      </Container>
    </Suspense>
  );
};
