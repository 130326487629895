import { useState, useCallback, useContext } from "react";
import StoreContext from "../contexts/StoreContext.jsx";

const useError = () => {
  const { errors, setErrors } = useContext(StoreContext);

  const setError = useCallback((field, message) => {
    setErrors((prevErrors) => {
      const newErrors = {
        ...prevErrors,
        [field]: message,
      };
      return newErrors;
    });
  }, []);

  const clearError = useCallback((field) => {
    setErrors((prevErrors) => {
      const { [field]: _, ...rest } = prevErrors;
      return rest;
    });
  }, []);

  const clearAllErrors = useCallback(() => {
    setErrors({});
  }, []);

  const getError = useCallback((field) =>  errors[field], [errors]);

  const hasErrors = useCallback(() => Object.keys(errors).length > 0, [errors]);

  return {
    errors,
    setError,
    clearError,
    clearAllErrors,
    getError,
    hasErrors,
  };
};

export default useError;
